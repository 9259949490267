@import '~styles/common';
.SideMenu {
    .bm-menu-wrap {
        width: 80vw !important;
        max-width: 350px !important;
    }
    .bm-burger-button {
        @include themed() {
            background: t($bg);
        }
    }
    .bm-burger-bars {
        @include themed() {
            background: t($text);
        }
    }
    .bm-menu {
        @include themed() {
            background: t($bg);
        }        
    }
    .bm-item-list {
        @include themed() {
            color: t($text);
        }        
    }
    .select-container {
        //transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        &.visible {
            max-height: 10000px;
        }
        &.hidden {
            overflow: hidden;
            max-height: 0;
        }
    }
    .section-title {
        margin-left: 10px;
        margin-top: 10px;
        font-size: 0.8em;
        display: inline-block;
        width: calc(100% - 8px);
        @include themed() {
            border-bottom: 1px solid t($border);
        }   
    }
    .section-title-label {
        float: left;
    }
    .section-description {
        font-size: 0.6em;
    }
    .bugs-list {
        padding-left: 14px;
        list-style: disc;
        margin-top: 0px;
    }
    .section-body {
        padding-left: 10px;
        font-size: 0.8em;
        font-weight: normal;
        margin-right: 10px;
        &.description {
            font-size: 0.6em;
            font-weight: 100;
        }
        a {
            @include themed() {
                color: t($link);
            }   
            &:visited {
                @include themed() {
                    color: t($link);
                }   
            }
        }
    }
    .faq-list {
        padding-left: 0px;
        margin: 0;
        list-style: none;
        .question {
            margin-bottom: 2px;
            font-weight: bold;
        }
        .answer {
            margin-bottom: 15px;
        }
    }
    .menu-trigger-wrapper {
        display: inline-block;
    }
    .menu-title {
        display: inline-block;
        float: left;
    }
    .section-icon {
        width: 15px;
        height: 15px;
        float: left;
        display: inline-block;
        background-position: center; 
        background-repeat: no-repeat;
        margin: 3px 8px 0 0;
        background-size: 15px 15px;
        @include themed() {
            filter: t($icon);
        }
        &.filter {
            background-image: url('../../assets/filter.png');
        }
        &.display-options {
            background-image: url('../../assets/palette.png');
        }
        &.attribution {
            background-image: url('../../assets/account.png');
        }
        &.faq {
            background-image: url('../../assets/help-circle.png');
        }
        &.bugs {
            background-image: url('../../assets/bug.png');
        }
    }
    .menu-icon {
        width: 20px;
        height: 20px;
        float: left;
        display: inline-block;
        background-position: center; 
        background-repeat: no-repeat;
        margin: 3px 10px 0 0;
        background-size: 20px 20px;
        &.settings {
            background-image: url('../../assets/settings.png');
        }
        &.resources {
            background-image: url('../../assets/book-open-page-variant.png');
        }
        &.about {
            background-image: url('../../assets/information.png');
        }
        @include themed() {
            filter: t($icon);
        }
    }
    .description-icon {
        width: 16px;
        height: 16px;
        float: left;
        display: inline-block;
        background-position: center; 
        background-repeat: no-repeat;
        margin: 2px 0px 0 8px;
        background-size: 16px 16px;
        cursor: pointer;
        background-image: url('../../assets/help-circle.png');
        @include themed() {
            filter: t($icon);
        }
    }
    .Collapsible {
        margin-bottom: 5px;
        &:last-of-type {
            margin-bottom: 12px;
        }
    }
    .Collapsible__trigger {
        width: calc(100% - 12px);
        height: 26px;
        cursor: pointer;
        @include themed() {
            background: t($bg-overlay);
        } 
        display: block;
        padding: 6px;
        user-select: none;
        transition: background 0.2s linear;
        outline: none;
        &:hover {
            @include themed() {
                background: t($bg-overlay-hover);
            } 
        }
        &.is-open {
            &:after {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
            }
        }
        &:after {
            background-image: url('../../assets/triangle.png');
            content: '';
            float: right;
            width: 12px;
            height: 12px;
            background-position: center; 
            background-repeat: no-repeat;
            background-size: 12px 12px;
            transform: rotate(270deg);
            -webkit-transform: rotate(270deg);
            transition: transform 0.1s linear;
            margin-top: 7px;
            @include themed() {
                filter: t($icon);
            }
        }
    }
    .Collapsible__contentOuter {
        padding: 0 10px 0 6px;
        @include themed() {
            background: t($bg-overlay);
        } 
    }
    .line-toggle {
        border-left: 2px solid;
    }
    .toggle-wrapper {
        width: 100%;
        margin-bottom: 10px;
        display: inline-block;
        position: relative;
        padding-left: 10px;
        label {
            display: inline-block;
            width: 100%;
        }
    }
    .react-toggle {
        .react-toggle-thumb {
            @include themed() {
                border: 1px solid t($border);
            }
        }
        @include themed() {
            border: 2px solid t($border);
        }
        border-radius: 26px;
        float: right;
        display: block;
    }
    .toggle-label {
        float: left;
        height: 24px;
        font-size: 0.9em;
        margin-right: 5px;
    }
    .toggle-wrapper {
        height: 24px;
        line-height: 24px;
        &.active {
            height: auto;
        }
    }
    .react-toggle--focus .react-toggle-thumb {
        box-shadow: none;
    }

    .custom-toggle.react-toggle--checked {
        &.neutral {
            .react-toggle-track {
                @include themed() {
                    background: t($link);
                }
            }  
        }
        &.red {
            .react-toggle-track {
                background-color: red;
            }
        }
        &.orange {
            .react-toggle-track {
                background-color: orange;
            }
        }
        &.yellow {
            .react-toggle-track {
                background-color: yellow;
            }
        }
        &.green {
            .react-toggle-track {
                background-color: green;
            }
        }
        &.blue {
            .react-toggle-track {
                background-color: blue;
            }
        }
        &.silver {
            .react-toggle-track {
                background-color: silver;
            }
        }
    }
}