//--colors
$bg--light: white;
$bg--dark: #2b2b2b;
$bg-overlay--light: #E8E8E8;
$bg-overlay--dark: #212121;
$bg-overlay-hover--dark: #191919;
$bg-overlay-hover--light: lightgray;
$text--light: black;
$text--dark: white;
$border--light: gray;
$border--dark: gray;
$hover--light: red;
$hover--dark: red;
$link--dark: lightblue;
$link--light: #1a8cff;
$icon--light: invert(100%);
$icon--dark: invert(0%);
$outage--dark: #ED4F32;
$outage--light: #ED4F32;