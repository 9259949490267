@import '~styles/common';
@keyframes color-change {
    0% { background: red; height: 32px; width: 32px; top: 11px; right: 11px}
    50% { background: grey; height: 30px; width: 30px; top: 12px; right: 12px }
    100% { background: red; height: 32px; width: 32px; top: 11px; right: 11px}
}

.RailAlerts {
    position: fixed;
    top: 10px;
    right: 10px;
    @include themed() {
        color: t($text);
    }
    z-index: 999; // 1 less than the side menu overlay

    .alert-menu-title {
        padding-left: 5px;
        height: 28px;
        margin-left: -5px;
        line-height: 28px;
        font-size: 14px;
        
        @include themed() {
            border-bottom: 1px solid t($border);
        }
    }
    .rail-alerts{
        //border-top: 1px solid grey;
        // Leave space for the zoom controls
        max-height: calc(100vh - 172px);
        overflow-y: auto;
    }
    .alerts-container {
        border-radius: 2px;
        max-height: calc(100vh - 20px);
        max-width: calc(100vw - 78px);
        overflow-y: auto;
        padding-left: 5px;
        //padding-right: 5px;
        z-index: 997;
        position: fixed;
        right: 12px;
        top: 12px;
        transition: transform 0.2s;
        @include themed() {
            background: t($bg);
            border: 1px solid t($border);
        }
    }

    .no-alerts {
        font-size: 12px;
        font-weight: normal;
        padding: 15px 0px;
        margin-left: 10px;
        margin-right: 15px;
        text-align: center;
    }
    
    .expand-button {
        position: fixed;
        cursor: pointer;
        top: 13px;
        right: 13px;
        height: 28px;
        width: 28px;
        @include themed() {
            background: t($bg);
        }
        border-radius: 50%;
        z-index: 998;
        user-select: none;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        background-position: center; 
        background-repeat: no-repeat;
        transition: border-radius 0.2s;

        &:before {
            content: "";
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            background-size: 18px 18px;
            background-position: center; 
            background-repeat: no-repeat;
            @include themed() {
                filter: t($icon);
            }           
        }
        &.expanded {
            &:before {
                background-image: url('../../assets/close.png');
            }
        }
        &.closed {
            &:before {
                background-image: url('../../assets/exclamation.png');
            }
        }
    }
    .expand-button-shadow {
        position: fixed;
        top: 12px;
        right: 12px;
        height: 30px;
        width: 30px;
        background: grey;
        border-radius: 50%;
        z-index: 996;
        transition: border-radius 0.2s;
        &.new-alerts {
            animation: color-change 2s infinite;
        }
    }

    .expanded {
        border-radius: 0;
        &.expand-button {
            height: 27px;
            width: 27px;
        }
        &.expand-button-shadow {
            height: 29px;
            width: 29px;
        }
    }

    .RailAlert {
        padding-bottom: 5px;
        padding-top: 5px;
        margin-right: 5px;
        border-bottom: 1px solid grey;
        max-width: 500px;
        &:last-of-type {
            border-bottom: none;
        }
    }
    .alert-header {
        height: 24px;
        line-height: 24px;
    }
    .line-indicators {
        margin-left: 5px;
        float: left;
    }
    .line-indicator {
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-weight: bold;
        border-radius: 50%;
        font-size: 12px;
        margin-right: 5px;
        float: left;
    }
    .alert-type-icon {
		width: 24px;
		height: 24px;
		background-repeat: no-repeat;
        background-size: contain;
        float: left;
        margin-right: 5px;
        @include themed() {
            filter: t($icon);
        }
    }
    .alert-type {
        float: left;
    }
    .date {
        float: right;
        font-size: 11px;
        line-height: 11px;
    }
    .dismiss {
        float: right;
        width: 12px;
        height: 12px;
        background-position: center; 
        background-repeat: no-repeat;
        background-image: url('../../assets/close.png');
        background-size: 12px 12px;
        margin: 1px 0px 0px 5px;
        cursor: pointer;
        @include themed() {
            filter: t($icon);
        }
    }
    .description {
        font-size: 12px;
        margin-left: 5px;
        margin-top: 5px;
    }
    .alert {
        background-image: url('../../assets/alert-circle-outline.png');
    }
    .delay {
        background-image: url('../../assets/clock-outline.png');
    }
}

@media only screen and (max-width: 900px) {
    .RailAlerts {
        .rail-alerts{
            max-height: calc(50vh - 54px);
        }
    }
}