@import '~styles/common';
.RailPredictions {
    .predictions-container {
        position: fixed;
        bottom: 12px;
        left: 12px;
        width: 288px;
        @include themed() {
            background: t($bg);
            border: 1px solid t($border);
            color: t($text);
        }
        border-radius: 2px;
        z-index: 999;
        padding: 5px;
        padding-right: 0px;
        padding-bottom: 0px;
        //overflow-y: auto;
        // Leave room for the burger menu
        max-height: calc(100vh - 78px);
        max-width: calc(100vw - 66px);
    }
    .station-name {
        display: inline-block;
        max-width: calc(100% - 26px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .section-header {
        font-size: 12px;
    }
    .outages-list {
        font-size: 12px;
    }
    .table-header {
        border-bottom: 1px solid grey;
        margin-left: -5px;
        padding-left: 5px;
        display: flex;
    }
    .table-body {
        max-height: calc(100vh - 152px);
        overflow-y: auto;
        //padding-top: 5px;
    }
    .table-row {
        display: flex;
        height: 22px;
        margin-bottom: 5px;
        line-height: 20px;
        margin-right: 5px;
    }
    .cell {
        display: inline-block;
        font-size: 12px;
        height: 20px;;
    }

    .first-row {
        border-top: 1px solid grey;
        //height: 26px;
        padding-top: 5px;
        &:first-of-type {
            border-top: 0px;
        }
    }

    .line-cell {
        width: 40px;
    }
    .destination-cell {
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }

    .minutes-cell {
        width: 60px;
        padding-left: 5px;
    }

    .car-cell {
        width: 30px;
        padding-left: 5px;
    }

    .loading {
        text-align: center;
        height: 40px;
        width: 100%;
        line-height: 40px;
    }

    .line-indicator {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        font-size: 8px;
        margin-left: 4px;
        float: left;
        font-weight: bold;
    }
    .close-button {
        height: 18px;
        width: 18px;
        user-select: none;
        text-align: center;
        background-position: center; 
        background-repeat: no-repeat;
        background-image: url('../../assets/close.png');
        background-size: 18px 18px;
        float: right;
        margin-right: 5px;
        cursor: pointer;
        @include themed() {
            filter: t($icon);
        }
    }
}

@media only screen and (max-width: 900px) {
    .RailPredictions {
        .predictions-container {
            max-height: 50vh;
        }
        .table-body {
            max-height: calc(50vh - 80px);
        }
    }
}