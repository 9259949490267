@import '~styles/common';
.MetroMap {
    height: 100vh;
    width: 100vw;
    .leaflet-container {
        height: 100%;
        width: 100%;
        @include themed() {
            background: t($bg-overlay);
        }
        .dc-outline {
            pointer-events: none;
        }
        path.line-layer {
            pointer-events: none;
        }
        .label-icon {
            pointer-events: none;
            @include themed() {
                color: t($text);
            }
        }
    }

    .leaflet-control-attribution {
        display: none;
    }
    .leaflet-top.leaflet-left {
        z-index: 999;
    }
    .leaflet-control-zoom {
        position: fixed;
        bottom: 12px;
        right: 12px;
        margin: 0px;
        border: none;
    
        .leaflet-control-zoom-in, .leaflet-control-zoom-out {
            border-radius: 50%;
            border-bottom-left-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
            border-top-left-radius: 50% !important;
            border-top-right-radius: 50% !important;
            border: 1px solid grey;
            line-height: 25px;
            @include themed() {
                background: t($bg);
            }
            color: transparent;
            font-weight: normal;
            margin-top: 5px;

            &:before {
                height: 28px;
                width: 28px;
                margin-left: -7px;
                margin-top: 1px;
                border-radius: 50%;
                position: absolute;
                content: '';
                background-position: center; 
                background-repeat: no-repeat;
                background-size: 18px 18px;
                @include themed() {
                    filter: t($icon);
                }
            }
        }
        .leaflet-control-zoom-in::before {
            background-image: url('../../assets/plus.png');
        }
        .leaflet-control-zoom-out::before {
            background-image: url('../../assets/minus.png');
        }
    }

    .station-icon {
        border-radius: 50%;
        background: white;
        border: 3px solid black;
        z-index: 200 !important;
        margin-top: -7px !important;
        margin-left: -7px !important;
        &::after {
            content: '';
            border-radius: 50%;
            display: block;
            width: 18px;
            height: 18px;
            margin-top: -5px;
            margin-left: -5px;
            background: transparent;
        }
    }

    .transfer-station-icon {
        border-radius: 50%;
        background: white;
        border: 6px solid black;
        z-index: 201 !important;
        margin-top: -10px !important;
        margin-left: -10px !important;
        -webkit-box-shadow: 0px 0px 0px 3px black; /* Safari, Chrome */

        
        &::after {
            content: '';
            border-radius: 50%;
            display: block;
            width: 16px;
            height: 16px;
            margin-top: -6px;
            margin-left: -6px;
            background: transparent;
            border: 2px solid white;
            //-moz-box-shadow:inset 2px 2px 5px #202020; /* Firefox */
            //box-shadow:inset 2px 2px 5px #202020; /* CSS3 */
        }
    }

    .has-outage {
        &::before {
            content: '';
            border-radius: 50%;
            display: block;
            position: absolute;
            width: 8px;
            height: 8px;
            margin-top: 0px;
            margin-left: 0px;
            @include themed() {
                background-color: t($outage);
            }
            animation: outage-indicator 3s infinite;
        }
    }

    .selected-station-icon {
        background: transparent;
        border-radius: 50%;
        box-shadow: 0 0 0 rgba(204,169,44, 0.4);
        animation: pulse 2s infinite;
        pointer-events: none;
    }

    .geolocation-button {
        position: fixed;
        cursor: pointer;
        bottom: 87px;
        right: 13px;
        height: 30px;
        width: 30px;
        @include themed() {
            background: t($bg);
        }
        border-radius: 50%;
        z-index: 998;
        user-select: none;
        &:after {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            position: absolute;
            background-position: center; 
            background-repeat: no-repeat;
            background-image: url('../../assets/crosshairs.png');
            background-size: 18px 18px;
            content: '';
            @include themed() {
                filter: t($icon);
            }
        }
        &.geolocating {
            &:after {
                -webkit-animation: rotateY 1s infinite linear;
                animation: rotateY 1s infinite linear;
            }
        }
    }

    .geolocation-button-shadow {
        z-index: 997;
        position: fixed;
        bottom: 86px;
        right: 12px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: grey;
    }

    @-webkit-keyframes rotateY {
        to { -webkit-transform: rotateY(360deg); }
    }
    @keyframes rotateY {
        to { transform: rotateY(360deg); }
    }

    @-webkit-keyframes pulse {
        0% {
          -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
        }
        70% {
            -webkit-box-shadow: 0 0 0 35px rgba(255,255,255, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        }
      }
    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
            box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
        }
        70% {
            -moz-box-shadow: 0 0 0 35px rgba(255,255,255, 0);
            box-shadow: 0 0 0 35px rgba(255,255,255, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
            box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        }
    }
    @keyframes outage-indicator {
        0% {
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        70% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}