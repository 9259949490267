@import '~styles/common';
html {
  /*font-family: 'Raleway', Arial, sans-serif;*/
  font-family: 'Lato', sans-serif, Helvetica, Arial;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 100%;
}

.__react_component_tooltip {
  pointer-events: initial;
  @include themed() {
    background: t($bg);
    border: 1px solid t($border) !important;
    color: t($text);
  }
}

.__react_component_tooltip.place-bottom:after {
  margin-left: -43px;
}

.__react_component_tooltip.place-bottom:before {
  margin-left: -45px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
}

button:disabled {
  opacity: 0.5;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    @include themed() {
      background: t($bg-overlay);
    }
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @include themed() {
      background: t($bg-overlay-hover);
    }
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @include themed() {
      background: t($bg-overlay-hover);
    }
  }