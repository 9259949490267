@import '~styles/common';
.StationSearch {
    .station-search-button {
        position: fixed;
        cursor: pointer;
        bottom: 12px;
        left: 12px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        z-index: 998;
        user-select: none;
        border: 1px solid grey;
        @include themed() {
            background: t($bg);
        }
        &::after {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            position: absolute;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../../assets/search.png');
            background-size: 18px 18px;
            content: '';
            @include themed() {
                filter: t($icon);
            }
        }
    }
    .station-search-dropdown-wrapper {
        position: fixed;
        cursor: pointer;
        bottom: 12px;
        left: 12px;
        z-index: 998;
        user-select: none;
        max-width: calc(100vw - 66px);
        width: 288px;
    }
}