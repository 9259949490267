div.global-tooltip.show {
    z-index: 9001;
    max-width: 260px;
    opacity: 1;
}

// These things fade out when display mode is enabled
.station-search-button, .leaflet-control-zoom, .geolocation-button, .bm-burger-button, .geolocation-button-shadow, .close-button, .dismiss, .expand-button, .expand-button-shadow {
    transition: opacity 0.5s ease-in-out;
}

.showcasing {
    .station-search-button, .leaflet-control-zoom, .geolocation-button, .bm-burger-button, .geolocation-button-shadow, .close-button, .dismiss, .expand-button, .expand-button-shadow  {
        opacity: 0;
        pointer-events: none;
    }
}